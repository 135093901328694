import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  Grid,
  useToast,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useState, useEffect, useCallback } from "react";
import { DEFAULT_TOAST_TIME } from "configuration";
import { LoaderBasic } from "components/LoaderBasic";
import React from "react";

const Direccion = ({
  client,
  validatePostalCode,
  updateClient,
  updateDirection,
  globalEffectUpdate,
}) => {
  const toast = useToast();
  const { direction } = client;
  const [postalCode, setPostalCode] = useState();
  const [postalCodeIsDisabled] = useState(
    direction.postal_code !== "" && direction.postal_code !== null
  );
  const [calle, setCalle] = useState(direction.street);
  const [calleIsDisabled, setCalleDisabled] = useState(
    direction.street !== "" && direction.street !== null
  );
  const [alcaldia, setAlcaldia] = useState("");
  const [ciudad, setCiudad] = useState("");
  const [estado, setEstado] = useState("");
  const [options, setOptions] = useState([]);

  const postalCodeValidate = useCallback(async () => {
    const callback = async () => {
      if (!postalCode || postalCode.length !== 5) {
        return; // No hace nada si postalCode no es válido
      }

      setLoading(true); // Asegúrate de establecer loading en true antes de hacer la validación

      let { data } = await validatePostalCode(postalCode);
      if (!data) {
        toast({
          position: "top",
          title: "Código Postal no válido",
          description: "Por favor ingrese un código postal válido",
          status: "info",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
        setLoading(false);
        return;
      }
      data = data.data;
      const cp = data[0];
      if (cp && !cp.error) {
        direction.postalCode = postalCode;
        setAlcaldia(cp.response.municipio);
        setCiudad(cp.response.ciudad);
        setEstado(cp.response.estado);
        setOptions(
          data.map((obj) => ({
            value: obj.response.asentamiento,
            label: obj.response.asentamiento,
          }))
        );
      } else if (data.data === 1) {
        toast({
          position: "top",
          title: "Código Postal no valido",
          description: "Por favor ingrese un código postal valido",
          status: "info",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      } else {
        toast({
          position: "top",
          title: "Código Postal no valido",
          description: "Por favor ingrese un código postal valido",
          status: "info",
          duration: DEFAULT_TOAST_TIME,
          isClosable: true,
        });
      }
      setLoading(false);
    };

    // Aquí se hace la llamada solo si el postalCode tiene exactamente 5 caracteres
    if (postalCode && postalCode.length === 5) {
      setLoading(true);
      await callback();
    }
  }, [postalCode, validatePostalCode, toast]);

  const { register, handleSubmit, watch } = useForm();
  const postalCodeWatch = watch("cp", postalCode);

  useEffect(() => {
    if (postalCodeWatch) {
      setPostalCode(postalCodeWatch);
      if (postalCodeWatch.length === 5) {
        postalCodeValidate();
      }
    }
  }, [postalCodeWatch, postalCodeValidate]);

  const [loading, setLoading] = useState(false);

  const onPostalCodeChange = (e) => {
    let value = e.target.value;
    if (value.length > 4) {
      setPostalCode(value);
      postalCodeValidate();
    }
  };

  const onSubmit = async (dataForm) => {
    setLoading(true);
    if (
      (dataForm.calle === "" && calleIsDisabled) ||
      (dataForm.cp === "" && postalCodeIsDisabled)
    ) {
      dataForm.calle = calle;
      dataForm.cp = postalCode;
    }

    let body = {
      calle: dataForm.calle === "" ? null : dataForm.calle,
      no_exterior: dataForm.exterior,
      no_interior:
        dataForm.interior === "" ? null : parseInt(dataForm.interior),
      cp: dataForm.cp,
      alcaldia,
      colonia: dataForm.colonia,
      ciudad,
      estado,
    };
    if (dataForm.calle) {
      body["calle"] = dataForm.calle;
    } else {
      body["calle"] = calle;
    }
    if (dataForm.cp) {
      body["cp"] = dataForm.cp;
    } else {
      body["cp"] = postalCode;
    }
    const { data, status } = await updateDirection(body);
    updateClient(data);
    if (status === 200) {
      globalEffectUpdate();
    } else if (data.error_code === 3) {
      toast({
        position: "top",
        title: "Información inválida",
        description: "Por favor no ingrese caracteres especiales",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setCalleDisabled(false);
      setCalle("");
    }
    setLoading(false);
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Heading
          color={"blue.700"}
          w="100%"
          textAlign={"center"}
          fontWeight="normal"
          mb="2%"
        >
          Por favor completa tu dirección
        </Heading>
        <Flex mt={2}>
          <FormControl mr="2%">
            <FormLabel
              htmlFor="calle"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Calle y números exteriores e interiores
            </FormLabel>
            <Input
              id="calle"
              {...register("calle")}
              defaultValue={calle}
              disabled={calleIsDisabled}
              placeholder="Calle"
              size="sm"
              rounded="md"
              // onKeyPress={handleKeyPress}
              required
            />
          </FormControl>
        </Flex>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={6}
        >
          <FormControl>
            <FormLabel htmlFor="cp" fontSize={"small"} fontWeight={"semibold"}>
              Código Postal
            </FormLabel>
            <Input
              id="cp"
              defaultValue={postalCode}
              placeholder="Código Postal"
              required
              size="sm"
              onChangeCapture={onPostalCodeChange}
              rounded="md"
              maxLength="5"
              minLength="5"
              disabled={postalCodeIsDisabled}
              {...register("cp")}
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="colonia"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Colonia
            </FormLabel>
            <Select
              {...register("colonia")}
              mr="5%"
              id="colonia"
              placeholder="Selecciona una opción"
              size="sm"
              rounded="md"
              required
              disabled={!options || options.length === 0}
            >
              {options?.map((colonia) => (
                <option key={colonia.value} value={colonia.value}>
                  {colonia.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="alcaldia"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Alcaldía o Municipio
            </FormLabel>
            <Input
              id="alcaldia"
              placeholder="Alcaldía o Municipio"
              size="sm"
              rounded="md"
              disabled
              value={alcaldia}
              required
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="state"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Estado
            </FormLabel>
            <Input
              type="text"
              id="state"
              size="sm"
              w="full"
              rounded="md"
              placeholder="Estado"
              value={estado}
              required
              disabled
            />
          </FormControl>
          <FormControl>
            <FormLabel
              htmlFor="ciudad"
              fontSize={"small"}
              fontWeight={"semibold"}
            >
              Ciudad
            </FormLabel>
            <Input
              type="text"
              id="ciudad"
              size="sm"
              rounded="md"
              placeholder=""
              disabled
              // onKeyPress={handleKeyPress}
              value={ciudad}
            />
          </FormControl>
          <Button
            isDisabled={
              // Aqui se valida que todos los campos esten llenos
              !postalCode || !alcaldia || !estado || !options
            }
            mt={2}
            type="submit"
            colorScheme={"blue"}
            variant={"solid"}
          >
            Continuar
          </Button>
        </Grid>
      </form>
    </>
  );
};

export default Direccion;
