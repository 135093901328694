import {
  Heading,
  Flex,
  FormControl,
  FormLabel,
  Stack,
  Input,
  Button,
  Center,
  Text,
  useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { clabe } from "clabe-validator";
import { DEFAULT_TOAST_TIME, BankDictionary } from "configuration";
import redux from "reducers/utils/Redux";
import React from "react";
import { LoaderBasic } from "components/LoaderBasic";

function BankDataPage({ client, saveBankData, globalEffectUpdate }) {
  const { register, handleSubmit } = useForm();
  const toast = useToast();

  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);

  const updateBancoOptions = (codigo) => {
    const clabeCheck = clabe.validate(codigo);
    if (!clabeCheck.ok) {
      toast({
        position: "top",
        title: "Datos no válidos",
        description: "Ingresa una cuenta CLABE válida",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      return;
    }
    const codigoBanco = codigo.slice(0, 3);
    if (!BankDictionary[codigoBanco]) {
      toast({
        position: "top",
        title: "Datos no válidos",
        description: "El banco ingresado no es válido",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
    } else {
      setBank(BankDictionary[codigoBanco]);
    }
  };

  const handleAccountChange = (e) => {
    let value = e.target.value;
    if (value.length === 18) {
      updateBancoOptions(value);
    }
    setAccount(value);
  };

  const onSubmit = async (dataForm) => {
    setLoading(true);
    const accountForm = document.getElementById("no_cuenta");

    handleAccountChange({ target: { value: accountForm.value } });

    const { data, status } = await saveBankData({
      number: dataForm.no_cuenta,
      banco: bank,
    });
    if (status === 200) {
      toast({
        position: "top",
        title: "Cuenta CLABE registrada con éxito",
        status: "success",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      globalEffectUpdate();
    } else if (data.error_code) {
      toast({
        position: "top",
        title: "Error al registrar la cuenta",
        description: "Por favor, intenta nuevamente",
        status: "error",
        duration: DEFAULT_TOAST_TIME,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  if (loading) {
    return <LoaderBasic />;
  }

  return (
    <>
      <Flex p={8} flex={1} align={"center"} justify={"center"}>
        <Stack spacing={4} w={"full"} maxW={"md"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Heading
              color={"blue.700"}
              w="100%"
              textAlign={"center"}
              fontWeight="normal"
              mb="2%"
            >
              Datos Bancarios
            </Heading>
            <Heading
              color={"blue.700"}
              w="100%"
              textAlign={"center"}
              fontWeight="bold"
              my="2%"
              fontSize={"md"}
            >
              Es indispensable que la cuenta bancaria que proporciones esté a tu
              nombre. En caso de que la cuenta esté a nombre de una persona
              diferente, tu cuenta será bloqueada y tu crédito no será
              depositado.
            </Heading>
            <FormControl isRequired id="cuentaBancaria">
              <FormLabel>Ingresa tu cuenta CLABE</FormLabel>
              <Input
                id="no_cuenta"
                {...register("no_cuenta")}
                defaultValue={client?.clabe}
                size="sm"
                value={account}
                onChange={handleAccountChange}
                rounded="md"
                required
                minLength={"18"}
                maxLength={"18"}
              />
            </FormControl>
            <FormControl id="banco">
              <FormLabel>Banco al cual pertenece</FormLabel>
              <Input
                id="banco"
                {...register("banco")}
                value={bank}
                size="sm"
                rounded="md"
                required
                w={"100%"}
                disabled
              />
            </FormControl>
            <Text fontSize={"md"} color={"blue.700"} textAlign={"center"}>
              No participan instituciones como Nu, Caja Libertad, Finsus,
              Crediclub, MercadoPago, Spin, Klar, entre otras.
            </Text>
            <Center>
              <Button
                mt={"2%"}
                colorScheme={"blue"}
                type="submit"
                isDisabled={bank === "" || account === ""}
              >
                Guardar
              </Button>
            </Center>
          </form>
        </Stack>
      </Flex>
    </>
  );
}

export default redux(BankDataPage);
